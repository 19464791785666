// extracted by mini-css-extract-plugin
export var arrowAnimation = "ComputerVision-module--arrowAnimation--302e8";
export var black = "ComputerVision-module--black--e5b5a";
export var card = "ComputerVision-module--card--a116d";
export var card1 = "ComputerVision-module--card1--b7849";
export var card2 = "ComputerVision-module--card2--6d714";
export var card3 = "ComputerVision-module--card3--98d10";
export var colGap = "ComputerVision-module--colGap--5afbd";
export var computerVision = "ComputerVision-module--computerVision--536d5";
export var concerns = "ComputerVision-module--concerns--a60ee";
export var description = "ComputerVision-module--description--315e6";
export var heading = "ComputerVision-module--heading--c3fdc";
export var newBtn = "ComputerVision-module--newBtn--ebfb8";
export var progress = "ComputerVision-module--progress--0c9fe";
export var secondIndex = "ComputerVision-module--secondIndex--98fac";
export var subDescription = "ComputerVision-module--subDescription--c417a";
export var subHeading = "ComputerVision-module--subHeading--40bf3";
export var talkBtn = "ComputerVision-module--talkBtn--380fc";
export var title = "ComputerVision-module--title--6d437";
export var white = "ComputerVision-module--white--c62f8";