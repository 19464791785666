// extracted by mini-css-extract-plugin
export var bannerCurve = "Banner-module--bannerCurve--01f51";
export var bannerHeading = "Banner-module--bannerHeading--dc34b";
export var btne = "Banner-module--btne--5093f";
export var contentWrapper = "Banner-module--contentWrapper--67dd3";
export var dedicated = "Banner-module--dedicated--bcb1f";
export var dedicatedBanner = "Banner-module--dedicatedBanner--146f2";
export var dedicatedHeadingStatic = "Banner-module--dedicatedHeadingStatic--07d46";
export var description = "Banner-module--description--2a401";
export var innerDedicated = "Banner-module--innerDedicated--3c356";
export var subTitle = "Banner-module--subTitle--02277";