import React from "react"
import * as styles from "./AIStatisticsTrend.module.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"

const AIStatisticsTrend = ({ strapiData }) => {
  return (
    <div className={styles.sectionContainer}>
      <Container className={styles.statisticContainer}>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={styles.headingContainer}
          >
            <h2
              className={styles.subTitle}
              dangerouslySetInnerHTML={{
                __html: strapiData?.title,
              }}
            />
            <p
              className={`${styles.description} mt-4`}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Row>
              {strapiData?.cards?.map(el => (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={`p-0`}
                  key={el?.title}
                >
                  <div className={styles.card}>
                    <img
                      src={el?.image1?.[0]?.localFile?.publicURL}
                      alt={"make smarter"}
                    />
                    <div>
                      <p className={styles.cardTitle}>
                        <span>{el?.title}</span>
                        <br />
                      </p>
                      <div className={styles.title2}>{el?.title2}</div>
                      <p className={styles.desc}>{el?.subTitle}  </p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AIStatisticsTrend
