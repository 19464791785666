// extracted by mini-css-extract-plugin
export var SliderWidth = "WordsFromClients-module--SliderWidth--1a593";
export var Trust = "WordsFromClients-module--Trust--fd0bc";
export var blueText = "WordsFromClients-module--blueText--55427";
export var clientContent = "WordsFromClients-module--clientContent--e2b95";
export var coma = "WordsFromClients-module--coma--c0d6f";
export var content = "WordsFromClients-module--content--579d6";
export var customModal = "WordsFromClients-module--customModal--43f84";
export var dec = "WordsFromClients-module--dec--f9200";
export var description = "WordsFromClients-module--description--ada8e";
export var designation = "WordsFromClients-module--designation--e92a8";
export var dot = "WordsFromClients-module--dot--07efa";
export var heading = "WordsFromClients-module--heading--f9105";
export var headingContainer = "WordsFromClients-module--headingContainer--ade69";
export var iconContainer = "WordsFromClients-module--iconContainer--5c317";
export var iconContainerLeft = "WordsFromClients-module--iconContainerLeft--739b5";
export var logos = "WordsFromClients-module--logos--f68cb";
export var logosBg = "WordsFromClients-module--logosBg--62cad";
export var name = "WordsFromClients-module--name--07e00";
export var playBtn = "WordsFromClients-module--playBtn--57355";
export var portfolioArrowIcon = "WordsFromClients-module--portfolioArrowIcon--4a15c";
export var portfolioArrowIconCover = "WordsFromClients-module--portfolioArrowIconCover--30ea6";
export var portfolioArrowRightIconCover = "WordsFromClients-module--portfolioArrowRightIconCover--8c360";
export var view = "WordsFromClients-module--view--60beb";