// extracted by mini-css-extract-plugin
export var card = "AIExpertiseSection-module--card--eeb0a";
export var cardCounting = "AIExpertiseSection-module--cardCounting--5b794";
export var cardHeading = "AIExpertiseSection-module--cardHeading--3bcf3";
export var cardsContainer = "AIExpertiseSection-module--cardsContainer--d78f0";
export var cta = "AIExpertiseSection-module--cta--8a03c";
export var description = "AIExpertiseSection-module--description--03fe7";
export var heading = "AIExpertiseSection-module--heading--3196e";
export var highlight = "AIExpertiseSection-module--highlight--3535d";
export var imgContainer = "AIExpertiseSection-module--imgContainer--a0f2b";
export var sectionContainer = "AIExpertiseSection-module--sectionContainer--58868";