// extracted by mini-css-extract-plugin
export var bgColor = "Faqs2-module--bgColor--501e1";
export var customFaqBg = "Faqs2-module--customFaqBg--16b82";
export var faqButton = "Faqs2-module--faqButton--fc8d5";
export var faqsNum = "Faqs2-module--faqsNum--75926";
export var faqsSub = "Faqs2-module--faqsSub--db2b9";
export var head = "Faqs2-module--head--2479b";
export var quesColor = "Faqs2-module--quesColor--b49eb";
export var quesColorSelected = "Faqs2-module--quesColorSelected--90a8b";
export var section = "Faqs2-module--section--40aff";
export var serial = "Faqs2-module--serial--50d36";
export var serial2 = "Faqs2-module--serial2--275fa";
export var staffFaqBg = "Faqs2-module--staffFaqBg--f31c5";
export var textColor = "Faqs2-module--textColor--ca0c8";