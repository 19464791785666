// extracted by mini-css-extract-plugin
export var Trust = "WorkPortfolio-module--Trust--56b3e";
export var arrowIcon = "WorkPortfolio-module--arrowIcon--39270";
export var arrowImage = "WorkPortfolio-module--arrowImage--e4340";
export var arrowLink = "WorkPortfolio-module--arrowLink--17a95";
export var btn = "WorkPortfolio-module--btn--03ce8";
export var btn2 = "WorkPortfolio-module--btn2--51f75";
export var caseImages = "WorkPortfolio-module--caseImages--0993f";
export var clientImgs = "WorkPortfolio-module--clientImgs--35a6f";
export var content = "WorkPortfolio-module--content--9e01a";
export var description = "WorkPortfolio-module--description--7be75";
export var headSearch = "WorkPortfolio-module--headSearch--56e5d";
export var heading = "WorkPortfolio-module--heading--97c0c";
export var logoImages = "WorkPortfolio-module--logoImages--7d407";
export var logos = "WorkPortfolio-module--logos--868e9";
export var rowGap = "WorkPortfolio-module--rowGap--599c6";
export var talkBtn = "WorkPortfolio-module--talkBtn--c0842";