// extracted by mini-css-extract-plugin
export var aiPoweredCard = "VisionDevelopment-module--aiPoweredCard--0ea36";
export var blueStar = "VisionDevelopment-module--blueStar--10975";
export var cardContent = "VisionDevelopment-module--cardContent--d2b23";
export var cardDescription = "VisionDevelopment-module--cardDescription--6526c";
export var counting = "VisionDevelopment-module--counting--d548b";
export var desc = "VisionDevelopment-module--desc--74c53";
export var imageContainer = "VisionDevelopment-module--imageContainer--09e33";
export var innerRow = "VisionDevelopment-module--innerRow--2a24b";
export var keyPoint = "VisionDevelopment-module--keyPoint--5468e";
export var keyPoints = "VisionDevelopment-module--keyPoints--7cb2b";
export var subTitle = "VisionDevelopment-module--subTitle--9ecf2";
export var title = "VisionDevelopment-module--title--a9eae";
export var visionDevelopment = "VisionDevelopment-module--visionDevelopment--86c83";
export var whiteStar = "VisionDevelopment-module--whiteStar--830d5";